/* eslint-disable @typescript-eslint/indent */
import { accountUrl } from '@/utils/common/env.constants';
import { Button } from '@pwskills/rachnaui';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { IMappedCourseDetails } from '@/interfaces/courseDescription';
import { encodeSlugUrl } from '@/utils/helper/helperFunction';
import { DOMAIN } from '@/utils/homePage/env.constants.utils';
import { COURSE_PURCHASE_TYPE, CourseTypes } from '@/utils/common/common.constants';
import UserDetailsContext from '@/context/UserDetailsContext';
import { GAevent } from '@/utils/firebase.utils';
import { useUTMParameters } from '@pwskills/rachnaui/hooks';
import { bankCourseIdsArray } from '@/utils/courseDescription/description.constants';
import useIsCourseBought from '@/hooks/useIsCourseBought';
import { LoginType } from '@/GlobalEnums/enums';
import useUserCourseDetails from '@/store/userCoursesDetails';
import Toaster from '../Toaster';
import LeadCapture from '../LeadCapture';
import { LeadCaptureEnquiryType, LeadCaptureType } from '../../../interfaces/leadCapture.enum';

type Props = {
  classes?: string;
  title?: string;
  slug?: string;
  pageFrom?: string;
  courseId?: string;
  categoryId?: string;
  isFree?: boolean;
  ctaOnCard?: string;
  courseType?: string;
  coursePurchaseType?: string;
  onClick?: (type: string) => void;
  isStickyNavBar?: boolean;
  isDescriptionPage?: boolean;
  disabled?: boolean;
  sectionFrom?: string;
  categoryName?: string;
  planCourses?: any;
};

const CardFooterButton2 = (props: Props) => {
  const {
    title,
    slug,
    pageFrom,
    courseId,
    categoryId,
    isFree,
    ctaOnCard,
    coursePurchaseType,
    courseType,
    onClick,
    isStickyNavBar,
    isDescriptionPage,
    classes = '',
    disabled,
    sectionFrom,
    categoryName,
    planCourses,
  } = props;
  const { isLoggedIn } = useContext(UserDetailsContext);
  const router = useRouter();
  const { userCoursesMap } = useUserCourseDetails();
  const [openModal, setOpenModal] = useState(false);
  const [mappedCourseDetails, setMappedCourseDetails] = useState<IMappedCourseDetails>({
    courseId: '',
    courseSlug: '',
    purchasedCourseSlug: '',
    purchasedCourseType: '',
  });
  const [toastMessage, setToastMessage] = useState({ isError: false, message: '' });
  const [openToaster, setOpenToaster] = useState(false);
  const utmQuery = useUTMParameters();

  const { user } = useContext(UserDetailsContext);
  const userData = user;
  const cName = router.query.courseSlug;
  const [isCourseBought, setIsCourseBought] = useState<boolean>(false);
  const [lessonId, setLessonId] = useState('');
  const [bankCourse, setBankCourse] = useState(false);
  const {
    checkIsCourseBought,
    returnLessonId,
    handleMappedCourses,
    isCourseBoughtForPlan,
    planCourseMappedDetails,
    planTypeCourseBought,
  } = useIsCourseBought();
  // const getIsCourseBought = async () => {
  //   try {
  //     const courseBoughtResponse: any = await CourseDescription.isCourseBought(courseId as string);
  //     if (courseBoughtResponse?.data) {
  //       setIsCourseBought(true);
  //       setLessonId(courseBoughtResponse?.data?.lessonId);
  //       if (courseBoughtResponse?.data?.courseId) {
  //         setMappedCourseDetails({
  //           courseId: courseBoughtResponse?.data?.courseId,
  //           courseSlug: courseBoughtResponse?.data?.courseSlug,
  //           lessonId: courseBoughtResponse?.data?.lessonId,
  //         });
  //       }
  //     }
  //   } catch (error: unknown) {
  //     setIsCourseBought(false);
  //     // eslint-disable-next-line no-console
  //     console.error((error as IError)?.error);
  //   }
  // };

  useEffect(() => {
    if (bankCourseIdsArray?.includes(courseId as string) && courseId) setBankCourse(true);
    if (isLoggedIn) {
      // getIsCourseBought();

      if (coursePurchaseType === COURSE_PURCHASE_TYPE.PLAN && planCourses) {
        setIsCourseBought(isCourseBoughtForPlan(planCourses));
        setMappedCourseDetails(planCourseMappedDetails(planCourses));
      } else {
        setIsCourseBought(checkIsCourseBought(courseId as string));
        setMappedCourseDetails(handleMappedCourses(courseId as string));
      }
      setLessonId(returnLessonId(courseId as string));
    }
  }, [courseId, isLoggedIn, userCoursesMap]);

  const sendGAEvent = (courseName: string, courseID: string, sectionName: string) => {
    GAevent('sk_course_watch', {
      user_id: userData._id,
      user_name: `${userData.firstName} ${userData.lastName}`,
      user_email: userData.email,
      user_phonenumber: `${userData.isdCode} ${userData.phoneNumber}`,
      course_name: courseName,
      course_id: courseID,
      section_name: sectionName,
    });
  };

  const goToPlanSection = () => {
    const section = document.getElementById('plans');
    const sectionTop = Number(section?.offsetTop) - 60;

    window.scrollTo({
      top: sectionTop,
      behavior: 'smooth',
    });
  };
  const cardFooterText = () => {
    const cardFooterButtonText =
      bankCourse && !isCourseBought
        ? 'Register Now'
        : bankCourse && isCourseBought
        ? 'Registered'
        : isCourseBought && isLoggedIn
        ? 'Go to course'
        : isStickyNavBar || isDescriptionPage
        ? coursePurchaseType === COURSE_PURCHASE_TYPE.PLAN
          ? 'Buy now'
          : isFree
          ? 'Enroll Now'
          : 'Buy Now'
        : isFree
        ? 'Enroll Now'
        : ctaOnCard === 'BUY_NOW'
        ? 'Buy Now'
        : 'Get Callback';

    return cardFooterButtonText;
  };

  const goToMyCourseFunction = () => {
    const sectionParam = sectionFrom ? `&section=${sectionFrom}` : '';

    if (coursePurchaseType === COURSE_PURCHASE_TYPE.PLAN) {
      // coming from description page
      if (planCourses) {
        if (mappedCourseDetails?.lessonId) {
          router.push(
            `${DOMAIN}/learn/course/${encodeSlugUrl(
              planTypeCourseBought?.slug || (slug as string)
            )}/${planTypeCourseBought?.courseId}/lesson/${mappedCourseDetails?.lessonId}?from=${
              router.asPath
            }&clickText=go_to_course&page_from=${pageFrom}${sectionParam}`
          );
        } else if (lessonId) {
          router.push(
            `${DOMAIN}/learn/course/${encodeSlugUrl(
              title || ''
            )}/${courseId}/lesson/${lessonId}?from=${
              router.asPath
            }&clickText=go_to_course&page_from=${pageFrom}${sectionParam}`
          );
        } else if (planTypeCourseBought?.purchasedCourseType === CourseTypes.PLACEMENT)
          router.push(
            `${DOMAIN}/learn/career-path/${encodeSlugUrl(
              planTypeCourseBought?.slug || (title as string)
            )}/${planTypeCourseBought?.courseId}/dashboard?from=${router.asPath}`
          );
        else {
          setToastMessage({ isError: true, message: 'Course has not started yet' });
          setOpenToaster(true);
        }
      } else {
        // coming from description page
        const slugUrl = encodeSlugUrl(
          (mappedCourseDetails?.purchasedCourseSlug as string) || title || (slug as string)
        );
        if (mappedCourseDetails?.lessonId) {
          router.push(
            `${DOMAIN}/learn/course/${slugUrl}/${courseId}/lesson/${mappedCourseDetails?.lessonId}?from=${router.asPath}&clickText=go_to_course&page_from=${pageFrom}${sectionParam}`
          );
        } else if (lessonId) {
          router.push(
            `${DOMAIN}/learn/course/${slugUrl}/${courseId}/lesson/${lessonId}?from=${router.asPath}&clickText=go_to_course&page_from=${pageFrom}${sectionParam}`
          );
        } else if (mappedCourseDetails?.purchasedCourseType === CourseTypes.PLACEMENT)
          router.push(
            `${DOMAIN}/learn/career-path/${slugUrl}/${courseId}/dashboard?from=${router.asPath}`
          );
        else {
          setToastMessage({ isError: true, message: 'Course has not started yet' });
          setOpenToaster(true);
        }
      }
    } else if (courseType && courseType === CourseTypes.PLACEMENT)
      router.push(
        `${DOMAIN}/learn/career-path/${encodeSlugUrl(title || '')}/${courseId}/dashboard?from=${
          router.asPath
        }&clickText=go_to_course&page_from=${pageFrom}${sectionParam}`
      );
    else if (lessonId)
      router.push(
        `${DOMAIN}/learn/course/${encodeSlugUrl(title || '')}/${courseId}/lesson/${lessonId}?from=${
          router.asPath
        }&clickText=go_to_course&page_from=${pageFrom}${sectionParam}`
      );
    else {
      setToastMessage({ isError: true, message: 'Course has not started yet' });
      setOpenToaster(true);
    }
  };

  const handleCourseBtnGAEvent = () => {
    sendGAEvent(cName as string, courseId as string, 'Go to Course Button');
  };

  return cardFooterText() ? (
    <>
      <Button
        disabled={
          (disabled &&
            (cardFooterText() === 'Enroll Now' ||
              cardFooterText() === 'Buy Now' ||
              cardFooterText() === 'Register Now') &&
            coursePurchaseType !== COURSE_PURCHASE_TYPE.PLAN) ||
          (bankCourse && isCourseBought)
        }
        className={
          classes !== ''
            ? classes
            : 'hover:bg-[none] bg-[#E58471] font-extrabold large:!font-bold  h-10 medium:!h-12 !min-w-[50%] !w-full'
        }
        variant="primary"
        onClick={() => {
          if (onClick) {
            onClick(cardFooterText());
          }
          if (isCourseBought && isLoggedIn) {
            goToMyCourseFunction();
          } else if (
            ctaOnCard === 'GET_CALLBACK' &&
            !isFree &&
            !isDescriptionPage &&
            !isStickyNavBar
          ) {
            setOpenModal(true);
          } else if (
            (isDescriptionPage || isStickyNavBar) &&
            coursePurchaseType === COURSE_PURCHASE_TYPE.PLAN
          ) {
            goToPlanSection();
          }
          // if plan type course scroll to plan section
          else if (coursePurchaseType === COURSE_PURCHASE_TYPE.PLAN) {
            router.push(
              `/course/${slug}/?goToPlanSection=true&source=${pageFrom}&source_position=course_buy_now`
            );
          } else if (isLoggedIn && !isCourseBought) {
            if (pageFrom === 'course_listing') {
              router.push(`/checkout?course_id=${courseId}&source=listing_page`);
            } else {
              router.push(`/checkout?course_id=${courseId}&source=home_page`);
            }
          } else {
            const newUrl = new URL(`${accountUrl}/login`);
            newUrl.searchParams.set('domain', window.location.host);
            newUrl.searchParams.set('redirectUrl', `/checkout?course_id=${courseId}`);
            newUrl.searchParams.set('position', pageFrom ? 'Nav_Enroll_now' : `enroll_now`);
            newUrl.searchParams.set('loginType', LoginType.COOKIE);
            if (utmQuery) {
              Object.entries(utmQuery).forEach(([key, value]) => {
                newUrl.searchParams.set(key, value as string);
              });
            }
            window.location.href = newUrl.href;
          }
          if (isCourseBought) handleCourseBtnGAEvent();
        }}
      >
        {cardFooterText()}
      </Button>

      {openModal && (
        <LeadCapture
          courseId={courseId}
          categoryId={categoryId}
          courseName={title}
          categoryName={categoryName}
          leadCaptureType={LeadCaptureType.MODAL}
          modelOpen={openModal}
          setModelOpen={setOpenModal}
          buttonText="Get Connected"
          img="/images/courseDescription/get-in-touch.svg"
          msgLineOne="We Got Your Response."
          msgLineTwo="Our Counsellor will reach out soon"
          type="ConnectWithCounsellor"
          enquiryType={LeadCaptureEnquiryType.GET_CALLBACK}
          enquiryTypeNPF="Get Callback"
          pageName={pageFrom}
        />
      )}

      {openToaster && (
        <Toaster
          open={openToaster}
          setOpen={setOpenToaster}
          text={toastMessage.message}
          variant={toastMessage?.isError ? 'error' : 'success'}
        />
      )}
    </>
  ) : (
    <div />
  );
};

export default CardFooterButton2;
