import { Button } from '@pwskills/rachnaui';
import Link from 'next/link';
import { CARD_TYPE } from '@/utils/common/common.constants';
import { useContext, useState } from 'react';
import UserDetailsContext from '@/context/UserDetailsContext';
import moEvent from '@/utils/moEngage';
// import Link from 'next/link';
import useRedirectToCourseDescription from '@/hooks/useRedirectToCourseDescription';
import useUserCourseDetails from '@/store/userCoursesDetails';
import CardFooterButton2 from './CardFooterButton2';
import LeadCapture from '../LeadCapture';
import {
  LeadCaptureEnquiryType,
  LeadCaptureType,
  ModalType,
} from '../../../interfaces/leadCapture.enum';

type Props = {
  title: string;
  slug: string;
  pageFrom: string;
  courseId: string;
  categoryId?: string;
  isFree: boolean;
  ctaOnCard: string;
  courseType: string;
  coursePurchaseType: string;
  onClick?: (type: string) => void;
  disabled?: boolean;
  cardType?: string;
  categoryName?: string;
  categorySlug?: string;
  position?: string;
  collegeName?: string;
  masterclassSlug?: string;
  masterClassTitle?: string;
};
// CardFooterButtons
const CardFooterButtons = (props: Props) => {
  const {
    title,
    slug,
    pageFrom,
    courseId,
    categoryId,
    isFree,
    ctaOnCard,
    coursePurchaseType,
    courseType,
    onClick,
    disabled,
    cardType,
    categoryName,
    categorySlug,
    position,
    collegeName,
    masterclassSlug,
    masterClassTitle,
  } = props;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const { user } = useContext(UserDetailsContext);
  const { onExploreClick } = useRedirectToCourseDescription();
  const { planCourseMap } = useUserCourseDetails();
  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const sendViewProgramMoEngageEvent = () => {
    if (user?._id) {
      const moEventData = {
        user_id: user?._id || '',
        username: `${user?.firstName} ${user?.lastName}` || '',
        phone_number: user?.phoneNumber || '',
        user_type: user?._id ? 'loggedin' : 'not_loggedin',
        course_name: title,
        course_type: courseType,
        ...(user?.verified && user?.email && { email: user.email }),
      };
      moEvent('online_degree_view_program', moEventData);
    }
  };

  const returnExploreNowUrl = () => {
    return `?from=${pageFrom}&position=${position}&source_position=course_explore${
      categoryName && categorySlug
        ? `&category_name=${categoryName}&category_slug=${categorySlug}`
        : ''
    }${
      masterclassSlug
        ? `&masterclassSlug=${masterclassSlug}&masterClassTitle=${masterClassTitle}`
        : ''
    }`;
  };
  const handleExploreClick = () => {
    if (onClick) onClick('explore');
    onExploreClick(slug, returnExploreNowUrl());
  };
  // to handle plan courses
  const returnSlug = () => {
    if (Object.hasOwnProperty.call(planCourseMap, courseId) && planCourseMap[courseId]?.courseSlug)
      return planCourseMap[courseId]?.courseSlug;
    return slug;
  };
  // to handle plan courses
  const returnCourseID = () => {
    if (Object.hasOwnProperty.call(planCourseMap, courseId))
      return planCourseMap[courseId]?.childCourse;
    return courseId;
  };
  const returnCoursePurchaseType = () => {
    // if (!user.firstName || !Object.hasOwnProperty.call(planCourseMap, courseId))
    return coursePurchaseType;
    // return '';
  };

  return (
    <div>
      {cardType === CARD_TYPE.NORMAL_COURSE ? (
        <div className="flex flex-row w-full gap-3">
          {/* button 1 */}
          <Button
            variant="secondary"
            className="hover:bg-[none] w-full bg-white font-extrabold large:!font-bold !text-[#E58471]"
            onClick={() => {
              handleExploreClick();
            }}
          >
            Explore
          </Button>
          {/* button 2 */}
          <CardFooterButton2
            title={title}
            slug={returnSlug()}
            pageFrom={pageFrom || ''}
            courseId={returnCourseID()}
            categoryId={categoryId}
            isFree={isFree}
            ctaOnCard={ctaOnCard || ''}
            coursePurchaseType={returnCoursePurchaseType()}
            courseType={courseType || ''}
            onClick={onClick}
            disabled={disabled}
            categoryName={categoryName}
          />
        </div>
      ) : (
        <div className="flex gap-3">
          <Button
            variant="secondary"
            className="hover:bg-[none] w-1/2 bg-white font-extrabold large:!font-bold !text-[#E58471]  !text-[14px] medium:!text-[16px] !leading-[22px] medium:!leading-[24px]"
            onClick={() => {
              if (onClick) {
                onClick('get_callback');
              }
              handleModalOpen();
            }}
          >
            Get Callback
          </Button>
          <Button
            variant="primary"
            className=" whitespace-nowrap w-1/2 !text-[14px] medium:!text-[16px] !leading-[22px] medium:!leading-[24px] "
            onClick={() => {
              if (onClick) {
                sendViewProgramMoEngageEvent();
                onClick('view_program');
              }
            }}
          >
            <Link href={`/degree/${slug}${pageFrom ? `?from=${pageFrom}` : ''}`}>View Program</Link>
          </Button>
        </div>
      )}
      {openModal && (
        <LeadCapture
          leadCaptureType={LeadCaptureType.MODAL}
          modelOpen={openModal}
          setModelOpen={setOpenModal}
          buttonText="Get Connected"
          img="/images/bhilai-lead-capture-modal-pic.svg"
          msgLineOne="We Got Your Response."
          msgLineTwo="Our Counsellor will reach out soon"
          type="ConnectWithCounsellor"
          enquiryType={LeadCaptureEnquiryType.ONLINE_DEGREE_GET_CALLBACK}
          enquiryTypeNPF="Online Degree_getcallback"
          modalType={ModalType.ONLINE_DEGREE}
          courseId={courseId}
          courseName={title}
          collegeName={collegeName}
          pageName={pageFrom}
        />
      )}
    </div>
  );
};

export default CardFooterButtons;
