const NavStub = [
  {
    href: 'overview',
    title: 'Overview',
  },
  {
    href: 'offerings',
    title: 'Offerings',
  },
  {
    href: 'curriculum',
    title: 'Curriculum',
  },
  {
    href: 'projects',
    title: 'Projects',
  },
  {
    href: 'certification',
    title: 'Certification',
  },
  {
    href: 'placement',
    title: 'Placement',
  },
  {
    href: 'mentors',
    title: 'Mentors',
  },
  {
    href: 'get-connected',
    title: 'Get In Touch',
  },
  {
    href: 'testimonial',
    title: 'Testimonial',
  },
  {
    href: 'faqs',
    title: 'FAQs',
  },
];

export const bankCourseIdsArray = [
  '65561043fec275bf8062a9c7',
  '653b999fd85a4d9660adf574',
  '6569d8b1d52e80024a44735a',
];
export default NavStub;
