import CourseDescription from '@/api/courseDescription';
import { domainUrl } from '@/utils/common/env.constants';
import { useRouter } from 'next/router';

export default function useRedirectToCourseDescription() {
  const router = useRouter();
  const onExploreClick = async (slug: string, params: string) => {
    try {
      const response: { data: string } = await CourseDescription.courseRedirectionURL(
        `${domainUrl}/course/${slug}`,
        params
      );
      if (Object.keys(response?.data)?.length > 0) {
        window.open(response?.data, '_self');
      } else {
        router.push(`/course/${slug}/${params}`);
      }
    } catch (error) {
      router.push(`/course/${slug}/${params}`);
    }
  };
  return {
    onExploreClick,
  };
}
