/* eslint-disable @typescript-eslint/indent */
import { Card, Typography } from '@pwskills/rachnaui';
import React, { useState } from 'react';
import { ArrowRedo } from '@pwskills/rachnaui/Icons';
import Link from 'next/link';
// import { useRouter } from 'next/router';
import { s3Url } from '@/utils/common/env.constants';
import { ICourses } from '@/interfaces/homePage';
import { dataLayer } from '@/utils/common/dataLayer.utils';
import { DOMAIN } from '@/utils/homePage/env.constants.utils';
import {
  CARD_TYPE,
  COURSE_PURCHASE_TYPE,
  DEGREE_ADMISSION_STATUS,
  REGISTRATION_STATUS,
} from '@/utils/common/common.constants';
import { getRandomId } from '../video/utils';
import { getPriceDetails, getRegistrationStatus } from '../../../utils/helper/helperFunction';
import SocialShareModal from '../SocialShareModal';
import Calendar from '../../../../public/images/coursesCard/Calendar';
import CardFooterButtons from './CardFooterButtons';

//  course card page
const CoursesCard = ({
  course,
  pageFrom,
  section,
  position,
  cardType = CARD_TYPE.NORMAL_COURSE,
  categorySlug,
  categoryName,
  categoryId,
  masterclassSlug,
  masterClassTitle,
}: {
  course: ICourses;
  pageFrom?: string;
  section?: string;
  position?: string;
  cardType?: string;
  categorySlug?: string;
  categoryName?: string;
  categoryId?: string;
  masterclassSlug?: string;
  masterClassTitle?: string;
}) => {
  const {
    img,
    title,
    slug,
    pricing,
    classTimings,
    _id,
    hashtagDetails,
    registration,
    coursePurchaseType,
    courseCardText,
    courseType,
    partnerInstituteDetails,
    thumbnailImgAltText,
    parentCategoryDetails,
  } = course || {};

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const priceDetails = getPriceDetails(pricing);
  const registrationStatus = getRegistrationStatus(registration);
  const startDate = classTimings?.startDate;

  let userCountry = 'IN';
  if (typeof window !== 'undefined') {
    userCountry = window.localStorage.getItem('USER_COUNTRY') || 'IN';
  }

  const getAdmissionStatus = () => {
    let admissionStatus = '';

    if (registration && Object.keys(registration).length) {
      const currentDate = new Date(); // Current date and time

      if (currentDate < new Date(registration?.startDate)) {
        // Admission hasn't begun yet
        admissionStatus = DEGREE_ADMISSION_STATUS.BEGIN_SOON;
      } else if (currentDate >= new Date(registration?.endDate)) {
        // Admission is closed
        admissionStatus = DEGREE_ADMISSION_STATUS.CLOSED;
      } else {
        // Calculate the difference in days between endDate and currentDate
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const differenceInDays =
          (new Date(registration?.endDate).getTime() - currentDate.getTime()) / millisecondsPerDay;
        if (differenceInDays <= 15) {
          // Closing soon condition: Start date is greater than today and end date is 15 days away
          admissionStatus = DEGREE_ADMISSION_STATUS.CLOSING_SOON;
        } else {
          // Default to open
          admissionStatus = DEGREE_ADMISSION_STATUS.OPEN;
        }
      }
    }
    return admissionStatus;
  };

  const getAdmissionStatusText = () => {
    const admissionStatus = getAdmissionStatus();
    let admissionStatusBgColor = '';
    let admissionStatusTextColor = '';

    switch (admissionStatus) {
      case DEGREE_ADMISSION_STATUS.BEGIN_SOON:
        admissionStatusBgColor = 'bg-blue-100';
        admissionStatusTextColor = 'text-blue-900';
        break;

      case DEGREE_ADMISSION_STATUS.OPEN:
        admissionStatusBgColor = 'bg-blue-100';
        admissionStatusTextColor = 'text-blue-900';
        break;

      case DEGREE_ADMISSION_STATUS.CLOSING_SOON:
        admissionStatusBgColor = 'bg-yellow-100';
        admissionStatusTextColor = 'text-yellow-900';
        break;

      case DEGREE_ADMISSION_STATUS.CLOSED:
        admissionStatusBgColor = 'bg-red-300';
        admissionStatusTextColor = 'text-white';
        break;

      default:
        admissionStatusBgColor = 'bg-blue-100';
        admissionStatusTextColor = 'text-blue-900';
        break;
    }

    return (
      <div
        className={`${admissionStatusBgColor} absolute bottom-[9px] left-4 px-3 py-[2px] rounded-[56px]`}
      >
        <Typography
          component="body-tiny"
          variant="semi-bold"
          className={`${admissionStatusTextColor}`}
        >
          {admissionStatus}
        </Typography>
      </div>
    );
  };

  const getRegistrationEndDateInFormat = () => {
    const endDate = new Date(registration.endDate);
    const day = endDate.getDate();
    const month = endDate.toLocaleString('en-US', { month: 'short' });
    const year = endDate.getFullYear().toString().slice(-2);

    // Function to get the ordinal suffix for the day
    const getOrdinalSuffix = (date: number) => {
      if (date >= 11 && date <= 13) {
        return 'th';
      }
      switch (date % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };

    // Format the date with the ordinal suffix for the day
    const formattedDate = `${`${day}'${getOrdinalSuffix(day)}`} ${month}, ${year}`;
    return formattedDate;
  };

  const getRegistrationEndDateText = () => {
    const admissionStatus = getAdmissionStatus();
    let admissionStatusTextColor = '';
    let admissionStatusImgColor = '';
    let admissionStatusText = 'Closes';

    switch (admissionStatus) {
      case DEGREE_ADMISSION_STATUS.BEGIN_SOON:
        admissionStatusTextColor = '#3D3D3D';
        admissionStatusImgColor = '#3D3D3D';
        admissionStatusText = 'Closes';
        break;

      case DEGREE_ADMISSION_STATUS.OPEN:
        admissionStatusTextColor = '#3D3D3D';
        admissionStatusImgColor = '#3D3D3D';
        admissionStatusText = 'Closes';

        break;

      case DEGREE_ADMISSION_STATUS.CLOSING_SOON:
        admissionStatusTextColor = 'text-red-300';
        admissionStatusImgColor = '#E9A091';
        admissionStatusText = 'Closes';

        break;

      case DEGREE_ADMISSION_STATUS.CLOSED:
        admissionStatusTextColor = 'text-gray-400';
        admissionStatusImgColor = '#B5BBC5';
        admissionStatusText = 'Closed';
        break;

      default:
        admissionStatusTextColor = '#3D3D3D';
        admissionStatusImgColor = '#3D3D3D';
        break;
    }

    return (
      <div className="flex gap-1 items-center">
        <Calendar imgColor={admissionStatusImgColor} />
        <Typography component="body-tiny" variant="semi-bold" className={admissionStatusTextColor}>
          Admission {admissionStatusText} on - {getRegistrationEndDateInFormat()}
        </Typography>
      </div>
    );
  };

  const sendDataLayerEvent = (type: string) => {
    const dataLayerObj = {
      event: 'course_card_click',
      category_id: categoryId || parentCategoryDetails?._id,
      click_text: type,
      section: section || '',
      course_name: title,
      course_id: _id,
      course_type: cardType === CARD_TYPE.NORMAL_COURSE ? coursePurchaseType : 'online_degree',
      source_page: cardType === CARD_TYPE.ONLINE_DEGREE_COURSE ? 'online_degree_page' : pageFrom,
    };
    dataLayer(dataLayerObj);
  };

  return (
    <Card
      key={getRandomId()}
      className="flex mt-5 relative !w-full medium:!w-[332px] large:!w-[411px] !mx-3 large:!mx-6 rounded-b-[20px] rounded-t-[20px] !shadow-light !shrink-0 flex-col hover:large:shadow-medium bg-white !p-0 !m-0"
      onClick={(e) => {
        e.stopPropagation();
        // router.push(`course/${slug}${pageFrom ? `?from=${pageFrom}` : ''}`);
      }}
    >
      {hashtagDetails && hashtagDetails[0]?.value && (
        <div className="ribbon absolute z-[2] -left-2 -top-4 h-11 !min-w-[140px] flex rounded rounded-bl-none  overflow-hidden  after:absolute after:bottom-3  after:left-0 after:-z-[1] after:border-4 after:border-[#82ADFF] after:border-l-transparent after:border-b-transparent">
          <Typography
            component="body-small"
            className="absolute bg-gradient-filter from-[#82ADFF]  to-[#F14EFF] py-1 text-center text-white shadow-md rounded !rounded-bl-none capitalize whitespace-nowrap w-[140px]"
          >
            {hashtagDetails[0].value}
          </Typography>
        </div>
      )}

      <div
        className={`flex flex-col flex-grow ${cardType === CARD_TYPE.NORMAL_COURSE ? '' : 'gap-2'}`}
      >
        {/* 1st component */}
        <Link
          href={
            cardType === CARD_TYPE.ONLINE_DEGREE_COURSE
              ? `/degree/${slug}${pageFrom ? `?from=${pageFrom}` : ''}`
              : `/course/${slug}${pageFrom ? `?from=${pageFrom}` : ''}&position=${position}${
                  categoryName && categorySlug
                    ? `&category_name=${categoryName}&category_slug=${categorySlug}`
                    : ''
                }`
          }
          onClick={() => {
            sendDataLayerEvent('card');
          }}
        >
          <div className="mx-auto w-full col-span-2 relative">
            {coursePurchaseType === COURSE_PURCHASE_TYPE.PLAN && (
              <div
                className="absolute bottom-0 flex items-center text-center py-2 w-full"
                style={{
                  background:
                    'linear-gradient(90deg, #D6FCE7 0%, #D7F0F0 39.89%, #D8E3FA 77.1%, #F3D8FA 100%)',
                }}
              >
                <Typography
                  component="body-tiny"
                  variant="bold"
                  className="text-gray-900 !text-[14px] !w-full"
                >
                  Premium Offerings Available
                </Typography>
              </div>
            )}
            <img
              // upload thumbnail logic is updated - newer uploads will return s3 bucket url and older one will return id
              src={`${
                img && img.startsWith('https') ? img : `${s3Url}/assets/uploads/thumbnails/${img}`
              }`}
              alt={thumbnailImgAltText || ''}
              className="!w-full medium:!w-[332px] large:!w-[411px] h-auto rounded-t-lg"
              width={411}
              height={200}
              loading="eager"
            />
            {cardType === CARD_TYPE.ONLINE_DEGREE_COURSE && getAdmissionStatusText()}
          </div>
        </Link>

        {/* 2nd component */}
        <div
          className={`flex flex-col justify-between p-4 gap-4 ${
            cardType === CARD_TYPE.NORMAL_COURSE ? 'flex-grow ' : 'h-full'
          }`}
        >
          <Link
            href={
              cardType === CARD_TYPE.ONLINE_DEGREE_COURSE
                ? `/degree/${slug}${pageFrom ? `?from=${pageFrom}` : ''}`
                : `/course/${slug}${pageFrom ? `?from=${pageFrom}` : ''}&position=${position}${
                    categoryName && categorySlug
                      ? `&category_name=${categoryName}&category_slug=${categorySlug}`
                      : ''
                  }`
            }
            onClick={() => sendDataLayerEvent('card')}
            className="h-full"
          >
            <div
              className={`flex flex-col justify-between gap-2 medium:gap-2 ${
                cardType === CARD_TYPE.NORMAL_COURSE ? '' : 'h-full'
              }`}
            >
              <div className="flex flex-row !justify-between ">
                <Typography
                  component="subheading"
                  variant="bold"
                  className="text-gray-900 line-clamp-2"
                >
                  {title}
                </Typography>

                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setModalOpen(true);
                  }}
                  className="min-w-[28px] max-w-[28px] min-h-[28px] max-h-[28px]  bg-gray-50 rounded-[50%] flex justify-center items-center "
                >
                  <ArrowRedo className="text-orange-500 w-4 h-4 cursor-pointer" />
                </button>
              </div>

              {cardType === CARD_TYPE.NORMAL_COURSE && (
                <Typography
                  component="body-small"
                  variant="semi-bold"
                  className="text-gray-650 line-clamp-2 h-[36px] medium:h-[44px]"
                >
                  {courseCardText}
                </Typography>
              )}
              {cardType === CARD_TYPE.ONLINE_DEGREE_COURSE && (
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col gap-1 medium:gap-2 large:gap-1 pb-3 border-b  border-b-stroke-200">
                    <div className="bg-white absolute right-0 top-0 px-[12.7px] py-1  rounded-bl-lg rounded-tr-lg">
                      <img
                        src={
                          partnerInstituteDetails && partnerInstituteDetails.instituteLogoFileName
                        }
                        alt="collage-logo"
                        className="w-12 h-12"
                      />
                    </div>

                    <div className="flex gap-1 items-center">
                      <img src="/images/coursesCard/Dummy_college.svg" alt="Dummy_college" />
                      <Typography
                        variant="regular"
                        component="body-small"
                        className="!text-[14px] !leading-[22px] text-gray-850"
                      >
                        {partnerInstituteDetails && partnerInstituteDetails.name}
                      </Typography>
                    </div>
                    {/* <div className="flex gap-4">
                      <div className="flex gap-1 items-center">
                        <img src="/images/coursesCard/NAAC_Logo.svg" alt="NAAC_Logo" />
                        <Typography
                          variant="regular"
                          component="body-small"
                          className="!text-[14px] !leading-[22px] text-gray-850"
                        >
                          NAAC Certified
                        </Typography>
                      </div>
                      <div className="flex gap-1 items-center">
                        <img src="/images/coursesCard/nirf_logo.svg" alt="nirf_logo" />
                        <Typography
                          variant="regular"
                          component="body-small"
                          className="!text-[14px] !leading-[22px] text-gray-850"
                        >
                          NIRF Certified
                        </Typography>
                      </div>
                    </div> */}
                  </div>

                  {getRegistrationEndDateText()}
                </div>
              )}
            </div>
          </Link>
          {cardType === CARD_TYPE.NORMAL_COURSE && (
            <div className="flex flex-row items-center !justify-between">
              {coursePurchaseType === COURSE_PURCHASE_TYPE.INDIVIDUAL &&
                pricing.pricingOnCardHero === 'PRICING_ON_CARD' &&
                pricing?.isFree === false && (
                  <div className="flex flex-row">
                    <div className="flex flex-row items-center gap-1">
                      <Typography
                        variant="semi-bold"
                        component="h3"
                        className="text-gray-900 !text-[14px] leading-[22px] medium:!text-[16px] medium:!leading-[24px] large:!text-[18px] large:!leading-[26px]"
                      >
                        {priceDetails && priceDetails?.currency}
                      </Typography>
                      <Typography
                        component="subheading"
                        className="text-gray-900 !text-[14px] leading-[22px] medium:!text-[16px] medium:!leading-[24px] large:!text-[18px] large:!leading-[26px]"
                        variant="bold"
                      >
                        {priceDetails && Math.ceil(priceDetails.currentPrice as number)}
                      </Typography>
                      {pricing?.discount !== 0 ? (
                        <div>
                          <Typography
                            component="body-small"
                            variant="strikethrough"
                            className="text-gray-650 !text-[12px] leading-[18px] medium:!text-[14px] medium:!leading-[22px]"
                          >
                            {priceDetails &&
                              Math.ceil(priceDetails.currentPrice as number) !==
                                Math.ceil(priceDetails.actualPrice as number) &&
                              Math.ceil(priceDetails.actualPrice as number)}
                          </Typography>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}

              {pricing?.isFree === true && (
                <div className="flex flex-row items-center">
                  <div className="flex flex-row items-center">
                    <Typography
                      component="body-regular"
                      variant="bold"
                      className="text-gray-900 mx-1"
                    >
                      Free
                    </Typography>
                  </div>
                </div>
              )}

              {coursePurchaseType === COURSE_PURCHASE_TYPE.INDIVIDUAL &&
                pricing.pricingOnCardHero === 'SHOW_REGISTRATION_DATE' && (
                  <div className="flex flex-row items-center">
                    <div className="flex flex-row items-center">
                      <Typography
                        component="subheading"
                        variant="bold"
                        className="text-gray-900 !text-[14px] leading-[22px] medium:!text-[16px] medium:!leading-[24px] large:!text-[18px] large:!leading-[26px]"
                      >
                        {registrationStatus}
                      </Typography>
                    </div>
                  </div>
                )}

              {coursePurchaseType === COURSE_PURCHASE_TYPE.PLAN ? (
                pricing?.isFree === true ? (
                  <div className="flex flex-row items-center">
                    <div className="flex flex-row items-center">
                      <Typography component="body-regular" variant="bold" className="text-gray-900">
                        Free
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-row items-center">
                    <div className="flex flex-row items-center">
                      <Typography component="subheading" variant="bold" className="text-gray-900">
                        {pricing.emiText}
                      </Typography>
                    </div>
                  </div>
                )
              ) : (
                ''
              )}

              {classTimings?.showStartDateOnCard === true && startDate && (
                <div className="flex">
                  <Typography
                    component="body-tiny"
                    variant="semi-bold"
                    className="text-[#037CBF] !text-[12px] mr-1 whitespace-nowrap"
                  >
                    Starts on
                  </Typography>
                  <Typography
                    component="body-tiny"
                    variant="semi-bold"
                    className="text-[#037CBF] !text-[12px] whitespace-nowrap"
                  >
                    {startDate &&
                      new Date(startDate).toLocaleDateString(`en-${userCountry}`, {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      })}
                  </Typography>
                </div>
              )}
            </div>
          )}
          {/* {cardType === CARD_TYPE.ONLINE_DEGREE_COURSE && (
            <div className="flex gap-1 items-center">
              <img src="/images/coursesCard/calendar.svg" alt="calendar" />
              <Typography component="body-tiny" variant="semi-bold" className="text-gray-850">
                Admission Closes on - 23’rd April,2024
              </Typography>
            </div>
          )} */}

          <CardFooterButtons
            title={title}
            slug={slug}
            pageFrom={pageFrom || ''}
            courseId={_id}
            categoryId={categoryId || parentCategoryDetails?._id}
            isFree={pricing.isFree}
            ctaOnCard={pricing.ctaOnCard || ''}
            coursePurchaseType={coursePurchaseType || ''}
            courseType={courseType || ''}
            onClick={sendDataLayerEvent}
            categorySlug={categorySlug}
            categoryName={categoryName || parentCategoryDetails?.name}
            position={position}
            disabled={
              registration?.startDate !== '' && registrationStatus !== REGISTRATION_STATUS.OPEN
            }
            cardType={cardType}
            collegeName={(partnerInstituteDetails && partnerInstituteDetails.name) ?? ''}
            masterclassSlug={masterclassSlug}
            masterClassTitle={masterClassTitle}
          />
        </div>
      </div>
      <SocialShareModal
        modelOpen={modalOpen}
        setModelOpen={setModalOpen}
        url={`${DOMAIN}/course/${slug || ''}`}
        title={`${title}`}
      />
    </Card>
  );
};

export default CoursesCard;
